<template>
  <div class="Participants">
    <div class="main content">
      <div class="operation">
        <el-button @click="add" type="primary">+ 新建</el-button>
        <div class="search_mid">
          <span>关键字：</span>
          <el-input v-model="Key" placeholder="请输入姓名" clearable></el-input>
          <el-button @click="search" type="primary" style="margin-left: 20px">查询</el-button>
        </div>
      </div>
      <!-- table表格 -->
      <el-table
        height="600px"
        ref="multipleTable"
        :data="PurchaseList"
        tooltip-effect="dark"
        style="width: 100%; margin-bottom: 10px"
        :header-cell-style="{
          color: '#333333',
          backgroundColor: 'rgb(240, 247, 253)',
          height:'40px'
        }"
        :row-style="{'height': '40px'}"
        border
      >
        <el-table-column type="selection" width="40" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="100px" align="center"></el-table-column>
        <el-table-column prop="Name" label="组织名称" align="left"></el-table-column>

        <el-table-column label="配置" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="
                margin-right: 10px;
                font-size: 16px;
                color: #3978e7;
                font-weight: 400;
                "
              @click="EditOrganization(scope.row)"
            >编辑</el-button>
            <el-button
              style="font-size: 16px; color: #3978e7; font-weight: 400"
              type="text"
              @click="DeleteOrganization(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 弹出框 -->
    <div class="add-procure">
      <el-dialog
        :visible.sync="addProcureDialog"
        width="520px"
        top="20vh"
        v-dialogDrag
        :before-close="Cancel"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">{{ AddOrEdit }}组织</span>
        </div>
        <div>
          <el-form label-width="80px" :model="PurchaseFrom">
            <el-form-item label="组织名称">
              <el-input v-model="iptValue" placeholder></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <button @click="Cancel">取消</button>
          <button @click="AddOrganizationData">保存</button>
        </div>
      </el-dialog>
    </div>
    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.rows"
      @pagination="OrganizationGetPageList"
    ></Pagination>
  </div>
</template>
<script>
import Administrators from "../../api/administrators.js";
import Pagination from "../../components/Pagination/index.vue";
export default {
  data() {
    return {
      PurchaseList: [],
      addProcureDialog: false,
      PurchaseFrom: {
        Name: "",
      },
      hospital: [],
      iptValue: "",
      total: 0,
      Key: "",
      listQuery: {
        page: 1,
        rows: 20,
      },
      AddOrEdit: "新增",
      Id: "",
    };
  },
  components: {
    Pagination,
  },
  created() {
    this.OrganizationGetPageList();
    console.log("dasdasds");
  },
  methods: {
    //点击新建
    add() {
      this.AddOrEdit = "新增";
      this.addProcureDialog = true;
    },
    //查询
    search() {
      this.listQuery.page = 1;
      this.OrganizationGetPageList();
    },
    //编辑
    EditOrganization(row) {
      this.AddOrEdit = "修改";
      this.Id = row.Id;
      this.type = row.type;
      this.level = row.level;
      this.addProcureDialog = true;
      this.iptValue = row.Name;
    },
    //取消按钮
    Cancel() {
      this.addProcureDialog = false;
      this.iptValue = "";
    },
    //删除
    DeleteOrganization(row) {
      const parameter = [];
      parameter.push(row.Id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Administrators.DeleteInfrastructureData(parameter).then((res) => {
          if (res.data.Status == 1) {
            this.PurchaseList = this.PurchaseList.filter((item) => {
              return item.Id != row.Id;
            });
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            alert(res.data.Message);
          }
          console.log(res);
        });
      });
    },
    remoteMethod() {},
    //保存
    AddOrganizationData() {
      console.log(this.iptValue);
      const parameter = {
        Name: this.iptValue,
      };
      if (this.AddOrEdit == "新增") {
        //添加数据
        Administrators.AddInfrastructureData(parameter).then((res) => {
          if (res.data.Status == 1) {
            this.$message({
              message: res.data.Message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.Message,
              type: "error",
            });
          }
        });
      } else {
        //修改数据
        const parameter = {
          Id: this.Id,
          Name: this.iptValue,
          type: this.type,
          level: this.level,
        };
        Administrators.EditInfrastructureData(parameter).then((res) => {
          console.log(res, "res");
          if (res.data.Status == 1) {
            this.OrganizationGetPageList();
            this.$message({
              message: res.data.Message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.Message,
              type: "error",
            });
          }
        });
      }
      this.addProcureDialog = false;
      this.iptValue = "";
    },

    //请求出书表格数据
    OrganizationGetPageList() {
      console.log(122);
      //列表
      const parameter = {
        Key: this.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
      };
      Administrators.SearchInfrastructureData(parameter).then((res) => {
        if (res.data.Status == 1) {
          this.total = res.data.Data.total;
          this.PurchaseList = res.data.Data.rows;
        } else {
          alert(res.data.Message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.Participants {
  ::v-deep .el-table__cell {
    padding: 0 !important;
  }

  width: 100%;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  height: 745px;
  .operation {
    margin-bottom: 20px;
    display: flex;
  }
  .search_mid {
    margin: 0 20px;
    span {
      display: inline-block;
      margin: 10px 5px;
      color: #666666;
      @include add-size($font_size_16);
    }
    .el-input {
      width: 150px;
      height: 40px;
      display: inline-block;
      ::v-deep .el-input__inner {
        @include add-size($font_size_16);
        height: 40px;
      }
    }
  }
}
</style>