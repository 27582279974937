// 管理员接口
import axios from "./Interceptor.js"
let address = window.global_config.BASE_URL2
let administrators = {
    AddUserData: (info) => {//管理员新增成员
        return axios({
            method: "post",
            url: `${address}User/Add`,
            data: info
        })
    },
    EditUserData: (info) => {//管理员编辑成员
        return axios({
            method: "post",
            url: `${address}User/Edit`,
            data: info
        })
    },
    DeleteUserData: (info) => {//管理员删除成员
        return axios({
            method: "post",
            url: `${address}User/Delete`,
            data: info
        })
    },
    UserGetPageListData: (info) => {//管理员成员列表
        return axios({
            method: "post",
            url: `${address}User/GetPageList`,
            data: info
        })
    },
    OrganizationGetPageListData: (info) => {//组织列表
        return axios({
            method: "post",
            url: `${address}Organization/GetPageList`,
            data: info
        })
    },
    AddOrganizationData: (info) => {//添加组织
        return axios({
            method: "post",
            url: `${address}Organization/Add`,
            data: info
        })
    },
    EditOrganizationData: (info) => {//编辑组织
        return axios({
            method: "post",
            url: `${address}Organization/Edit`,
            data: info
        })
    },
    DeleteOrganizationData: (info) => {//删除组织
        return axios({
            method: "post",
            url: `${address}Organization/Delete`,
            data: info
        })
    },
    GetMapData: (info) => {//权限矩阵
        return axios({
            method: "post",
            url: `${address}Role/GetMap`,
            data: info
        })
    },
    AddJurisdictionData: (info) => {//设置权限
        return axios({
            method: "post",
            url: `${address}Role/Add`,
            data: info
        })
    },
    createAuth: (info) => {//设置权限
        return axios({
            method: "post",
            url: `${address}Role/Add`,
            data: info
        })
    },
    createRole: (info) => {//设置权限
        return axios({
            method: "post",
            url: `${address}/Sync/Role/Add`,
            data: info
        })
    },
    NoPagingOrganizationData: (info) => {//无分页组织
        return axios({
            method: "post",
            url: `${address}Organization/List`,
            data: info
        })
    },
    GetPageListData: (info) => {//列表
        return axios({
            method: "post",
            url: `${address}Role/GetPageList`,
            data: info
        })
    },
    GetRoleData: (info) => {//获取某个角色权限信息
        return axios({
            method: "get",
            url: `${address}Role/Get`,
            params: info
        })
    },
    EditJurisdictionData: (info) => {//更新角色权限信息
        return axios({
            method: "post",
            url: `${address}Role/Edit`,
            data: info
        })
    },
    DeleteJurisdictionData: (info) => {//删除角色权限信息
        return axios({
            method: "post",
            url: `${address}Role/Delete`,
            data: info
        })
    },

    SearchInfrastructureData: (info) => {//获取基建数据
        return axios({
            method: 'post',
            url: `${address}Unit/GetPageList `,
            data: info
        })
    },
    AddInfrastructureData: (info) => {//添加基建数据
        return axios({
            method: 'post',
            url: `${address}Unit/Add `,
            data: info
        })
    },
    EditInfrastructureData: (info) => {//编辑基建数据
        return axios({
            method: 'post',
            url: `${address}Unit/Edit `,
            data: info
        })
    },
    DeleteInfrastructureData: (info) => {//删除基建数据
        return axios({
            method: 'post',
            url: `${address}Unit/Delete `,
            data: info
        })
    },
    //获取个人信息
    GetUserMy: () => {
        return axios({
            method: 'post',
            url: `${address}User/My`
        })
    },
    //保存个人信息
    KeepUserMy: (info) => {
        return axios({
            method: 'post',
            url: `${address}User/SaveMyPassword`,
            data: info
        })
    },
    //获取权限前置接口
    preAuth: (info) => {
        return axios({
            method: 'post',
            url: `${address}Sync/Role/Add `,
            data: info
        })
    },
}

export default administrators
